import { Mask } from 'maska';

export const useMask = (mask: string, value?: any, options?: any) => {
  const maska = new Mask({ mask, ...options });
  const maskedValue = computed(() => maska?.masked(value ? String(value) : '#'));
  const unmaskedValue = computed(() => maska?.unmasked(String(value)));

  return {
    maska,
    maskedValue,
    unmaskedValue,
  };
};
